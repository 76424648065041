<template>
    <div class="bg">
        <v-container>
            <v-btn fixed top class="mt-10" elevation="2" right to="/configuracao" fab><v-icon dark>mdi-cog-outline</v-icon></v-btn>
            <v-row>
                <v-col cols="3" md="4"/>
                <v-col cols="6" md="4" style="position: relative;" class="text-center pb-0">
                    <div style="margin:0 auto; border-radius: 50%; border:5px solid #005FAB; clear: both; overflow: hidden; width: 180px; height: 180px; display: block; background-color: #FFFFFF;">
                        <img v-auth-image="`${apiUrl}usuario/${usuarioSessao.id_usuario}/imagem/thumb.jpg`" style="max-height: 180px; max-width: 180px; float: left; display: block; margin: 0 auto 0 auto;"/>
                    </div>
                    <v-file-input accept="image/jpg" hide-input prepend-icon="mdi-pencil-circle" style="clear: both; margin:-30px auto 0 auto; max-width: 25px;" @change="adicionarArquivo" />
                </v-col>
                <v-col cols="3" md="4"/>
                <v-col cols="12">
                    <h1>{{usuarioSessao.usuario}}</h1>
                </v-col>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" sm="6"><v-card color="#005FAB" dark class="rounded-lg" to="/cursos"><v-card-title><v-icon large class="mr-2">mdi-star</v-icon> <h2 style="color: #FFFFFF;">Meus cursos</h2></v-card-title></v-card></v-col>
                        <!--<v-col cols="12"><v-card class="rounded-lg"><v-card-title><v-icon x-large>mdi-flag-outline</v-icon> <h2 style="color: #707070;">Nível do idioma</h2></v-card-title></v-card></v-col>-->
                        <!--<v-col cols="12"><v-card class="rounded-lg" to="/financeiro"><v-card-title><v-icon x-large>mdi-currency-usd</v-icon> <h2 style="color: #707070;">Financeiro</h2></v-card-title></v-card></v-col>-->
                        <v-col cols="12" sm="6"><v-card class="rounded-lg" to="/tradutor"><v-card-title><v-icon large class="mr-2" color="#005FAB">mdi-translate</v-icon> <h2 style="color: #005FAB;">Tradutor</h2></v-card-title></v-card></v-col>
                        <v-col cols="12" sm="6"><v-card class="rounded-lg" to="/youglish"><v-card-title><v-icon large class="mr-2" color="#005FAB">mdi-flag-outline</v-icon> <h2 style="color: #005FAB;">YouGlish</h2></v-card-title></v-card></v-col>
                        <v-col cols="12" sm="6"><v-card class="rounded-lg" to="/livro_fisico"><v-card-title><v-icon large class="mr-2" color="#005FAB">mdi-book-outline</v-icon> <h2 style="color: #005FAB;">Livros</h2></v-card-title></v-card></v-col>
                        <v-col cols="12" sm="6"><v-card class="rounded-lg" target="_blank" href="https://www.excellentglobal.store"><v-card-title><v-icon large class="mr-2" color="#005FAB">mdi-store</v-icon> <h2 style="color: #005FAB;">Comprar Material</h2></v-card-title></v-card></v-col>
                        <v-col cols="12" sm="6"><v-card class="rounded-lg" @click="sair"><v-card-title><v-icon large class="mr-2" color="#005FAB">mdi-logout</v-icon> <h2 style="color: #005FAB;">Sair</h2></v-card-title></v-card></v-col>

                        <PerfilRevisar/>

                        <v-col cols="12" sm="6">
                            <v-card class="rounded-lg">
                                <v-card-title style="background-color: #005FAB; color: #FFFFFF;"><v-icon large class="mr-2" color="#FFFFFF">mdi-flag-outline</v-icon><h2 style="color: #FFFFFF;">Minhas metas</h2></v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col class="mt-3" cols="12" v-for="(m, i) in metas.lista" :key="i">
                                            <v-row style="border-top:1px solid #e5e5e5;">
                                                <v-col class="pr-0" cols="2" :key="i" v-if="m.imagem_perfil === 't'">
                                                    <img width="100%" style="border-radius: 50%;" v-auth-image="`${apiUrl}usuario/${m.id_usuario}/imagem/thumb.jpg`"/>
                                                </v-col>
                                                <v-col :cols="m.imagem_perfil === 't' ? 10 : 12" :key="i">
                                                    <p class="text--primary mb-1"><strong>{{m.usuario}}</strong></p>
                                                    <p style="white-space: pre-line;" class="mb-0" v-html="m.meta"/>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-divider/>
                                <v-card-actions style="background-color: #FAFAFA;">
                                    <v-spacer/>
                                    <v-btn text to="/metas">Ver todas</v-btn>
                                    <v-spacer/>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12"><v-img width="70%" max-width="450px" :src="require(`@/assets/img/logo-azul.svg`)" style="margin:30px auto;"/></v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import {mapState} from "vuex";
import axios from "axios";
import PerfilRevisar from "@/Views/Perfil/Components/PerfilRevisar.vue";
export default {
    name: "Perfil",
    components: {PerfilRevisar},
    computed : {
        ...mapState(['usuarioSessao', 'baseUrl', 'apiUrl', 'usuarioSessao'])
    },
    data() {
        return {
            registro : {},
            metas : {lista : []},
            carregando : false
        }
    },
    methods : {
        adicionarArquivo(arquivo) {
            let reader = new FileReader()
            reader.readAsDataURL(arquivo)
            let that = this
            reader.onload = function () {
                that.registro.arquivo = reader.result.split('base64,')[1]
                that.salvar()
            }
        },
        metaListar() {
            axios.post(`${this.baseUrl}aluno/meta_listar`).then((res) => {
                this.metas.lista = res.data.lista.filter((v, i) => {
                    console.log(i)
                    return i < 3
                })
            })
        },
        salvar() {
            this.carregando = true
            return axios.post(`${this.apiUrl}usuario/imagem/alterar`, this.registro).then( () => {
                this.registro = {}
                this.carregando = false
                location.reload()
            }).catch(() => {
                this.registro = {}
                this.carregando = false
            })
        },
        sair() {
            axios.post(`${this.apiUrl}login/sair`, {authorization : this.usuarioSessao.sessao_token}).then(() => {
                this.drawer = false
                this.$store.commit('usuarioLogin', null)
                localStorage.clear()
                delete axios.defaults.headers.common['authorization']
                this.erro = null
                if(this.$router.currentRoute.path.indexOf('recuperar_senha') === -1 && this.$router.currentRoute.path !== '/') {
                    this.$router.push('/login')
                }
            })
        }
    },
    activated() {
        this.metaListar()
    }
}
</script>

<style>
    h1 {text-align: center; font-size: 30px; color: #005FAB;}
    h2 {font-weight: normal; font-size: 25px; line-height: 130%;}
    .bg {background-image: url("~@/assets/img/bg-perfil.png"); background-repeat: no-repeat; background-position-x: center; background-position-y: -250px;}
    .v-input__icon button {color: #005FAB!important; zoom: 2.0; background-color: #FFFFFF; border-radius: 50%;}
</style>