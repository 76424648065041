<template>
    <v-col cols="12" sm="6" v-if="revisar.total > 0">
        <v-card class="rounded-lg">
            <v-card-title style="background-color: #005FAB; color: #FFFFFF;"><v-icon large class="mr-2" color="#FFFFFF">mdi-alert-decagram-outline</v-icon><h2 style="color: #FFFFFF;">Importante revisar</h2></v-card-title>
            <v-list>
                <template v-for="(r, i) in revisar.lista">
                    <v-divider v-if="i > 0" :key="`d_${i}`"/>
                    <v-list-item :key="`_${i}`" style="cursor: pointer;" @click="$router.push(`/curso/material/conteudo/${r.id_turma}/${r.id_conteudo}/${r.id_conteudo_revisao}`)">
                        <v-list-item-content>
                            <v-list-item-title>{{r.conteudo}}</v-list-item-title>
                            <v-list-item-subtitle>Motivo: Ausência em aula</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action><v-chip small>{{r.level}}</v-chip></v-list-item-action>
                    </v-list-item>
                </template>
            </v-list>
        </v-card>
    </v-col>
</template>

<script>
import axios from "axios";
import {mapState} from "vuex";

export default {
    name: "PerfilRevisar",
    computed : {
        ...mapState(['apiUrl'])
    },
    data() {
        return {
            revisar : {
                lista : [],
                total : 0
            }
        }
    },
    methods : {
        revisaoListar() {
            axios.post(`${this.apiUrl}aluno/conteudo/revisar/listar`).then( (res) => {
                this.revisar = res.data
            })
        }
    },
    async activated() {
        await this.revisaoListar();
    }
}
</script>

<style scoped>

</style>